[aurelia-app],
router-view {
  display: contents;
}

@media (width <= 350px) {
  html,
  body {
    font-size: 14px;
  }
}

/* FIXME */
.intercom-launcher,
.intercom-namespace iframe.intercom-launcher-frame {
  display: none;
}

.intercom-messenger-frame + iframe.intercom-launcher-frame {
  display: block;
}

.button--free {
  font: inherit;
  text-decoration: inherit;

  &::part(control) {
    font: inherit;
    align-items: inherit;
    justify-content: inherit;
    padding: inherit;
  }

  &::part(content) {
    flex-grow: 1;
  }
}

/* TODO: Fix override issue in JB */
.button-icon-size--5::part(icon-start),
.button-icon-size--5::part(icon-end) {
  --icon-size: var(--spacer-5);
}

.group-label {
  display: block;
  font-weight: 500;
  font-size: var(--font-size-sm);
  margin-bottom: var(--spacer-1);
}

.ui-option-group-label {
  color: var(--color-base-400);
  padding: var(--spacer-2) var(--form-control-padding-x) 0;
  font-size: var(--font-size-sm);
  font-weight: 500;
  pointer-events: none;
}

/* Old UI elements */
.alert {
  position: relative;
  padding: var(--spacer-4);
  font-size: var(--font-size-sm);
  border-radius: var(--border-radius-lg);

  & h1,
  & h2,
  & h3 {
    font-size: var(--font-size-lg);
    margin-bottom: var(--spacer-2);
  }

  & h4,
  & h5 {
    font-size: 1rem;
    margin-bottom: var(--spacer-1);
  }
}

.alert--info {
  background-color: #fcf6df;
}

.alert--custom-content {
  background-color: var(--color-base-100);
}

.dir-rtl {
  direction: rtl;
}

.card {
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-lg);
}

@media (--screen-lg-up) {
  .lg\:card {
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius-lg);
  }
}

.helper {
  color: var(--helper-color);
  font-size: var(--font-size-sm);
  line-height: var(--leading-snug);
}

.stacked-photo-img-mask {
  position: absolute;
  bottom: 1px;
  right: 1px;
  width: 32px;
  height: 32px;
  overflow: hidden;

  & img {
    border: 0;
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.menu-container {
  position: relative;
  display: inline-flex;
}

.form-field-label-icon-button-container > ui-button {
  position: absolute;
  transform: translateY(-50%);
}

.toolbar {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  height: var(--spacer-14);
  justify-content: space-between;
  background-color: var(--color-surface);
  z-index: 10;
}

.toolbar-cell {
  align-items: center;
  display: flex;
  flex: 1 0 0;
}

compose {
  display: block;
}

.doc-content,
.doc-content.unreset {
  & h2 {
    margin-top: var(--spacer-12);
  }
}

.list-item--read {
  background-color: var(--color-read);
}

.list-item--active {
  background-color: var(--color-selected-highlight);
}

.empty {
  color: var(--color-placeholder);
  border: 2px dashed var(--color-base-200);
  background-color: var(--color-base-50);
}

.app-info-bottom-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  justify-content: center;
  align-items: center;
  height: 30px;
  color: #fff;
  font-size: var(--font-size-sm);
  font-weight: 500;
  cursor: default;
  background-color: var(--color-info);
  z-index: 60;
}

.app-info-bottom-toolbar--open .app-info-bottom-toolbar {
  display: flex;
}

.route--signup ui-app-shell::part(bottom-toolbar) {
  display: none;
}

body notification-host {
  @media (--screen-md-up) {
    justify-content: flex-end;
  }
}

/* Forms */

/* Safari issue with date field */
.safari.desktop ui-text-field[type="date"]::part(control) {
  color: rgba(0, 0, 0, 0.2);
}

.safari.desktop
  ui-text-field[type="date"]::part(control)::-webkit-datetime-edit {
  color: var(--form-control-color);
}

.inner-section {
  padding: var(--spacer-6) var(--spacer-4);

  @media (--screen-md-up) {
    padding: var(--spacer-8) var(--spacer-6);
  }

  @media (--screen-lg-up) {
    padding-left: var(--spacer-8);
    padding-right: var(--spacer-8);
  }
}

.inner-list-item,
.rp-collection-list-item--account-list {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 0 var(--spacer-4);

  & ui-action-button-group {
    margin-right: calc(var(--spacer-2) * -1);
  }
}

.scrollbar-hidden {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.side-nav-item {
  color: inherit;
  display: flex;
  align-items: center;
  min-height: var(--spacer-11);
  padding: var(--spacer-2) var(--spacer-4);
  background-color: inherit;
  text-decoration: none;
  border-radius: var(--rounded-xl);
}

.side-nav-item--active {
  font-weight: 500;
  color: var(--color-base-500);
  background: var(--color-base-100);
}

.static-control-replacement {
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 0;
  overflow: hidden;

  & > * {
    transform: translate3d(var(--spacer-2), 0, 0);
    transition: transform 200ms cubic-bezier(0.45, 0.35, 0.16, 0.78);
  }
}

.page-tabs {
  position: relative;
  margin: calc(var(--spacer-2) * -1) calc(var(--spacer-4) * -1) 0;
  z-index: 31;

  @media (--screen-md-down) {
    min-width: auto;
    max-width: 100vw;
  }
}

.unreset a,
.link {
  color: var(--link-color);
  text-decoration: underline;
  outline: none;

  &:hover {
    color: var(--link-color-hover);
  }

  &:focus {
    box-shadow:
      0 0 0 2px var(--color-surface),
      0 0 0 4px var(--form-control-border-color-focus);
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }
}

.break-anywhere {
  overflow-wrap: anywhere;
}

.stacked-photo-square {
  border-radius: 8px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(50% - 38px);
  z-index: 1;
  box-sizing: border-box;
}

.stacked-photo-square-transform {
  transform: translateY(var(--shift, 0)) translateX(var(--shift, 0));
}

.aspect-square {
  aspect-ratio: 1;
}

.shadow-outline-2.shadow-surface {
  box-shadow: 0 0 0 2px var(--color-surface);
}

ui-dialog.dialog--form {
  @media (--screen-sm-down) {
    --dialog-min-width: calc(100vw - var(--spacer-8));

    &::part(control) {
      width: 100vw;
      max-width: 100vw;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
    }

    &::part(header),
    &::part(content),
    &::part(footer) {
      padding-left: var(--spacer-4);
      padding-right: var(--spacer-4);
    }
  }

  @media (--screen-md-up) {
    --dialog-min-width: 640px;
  }
}

ui-dialog.dialog--filter {
  &::part(header) {
    margin-bottom: 1px;
    box-shadow: 0 1px 1px 0 var(--color-border);
  }

  &::part(footer) {
    justify-content: space-between;
  }

  @media (--screen-sm-down) {
    &::part(primary-button) {
      flex-grow: 1;
    }
  }

  & ui-form > form {
    padding-top: var(--spacer-4);
    padding-bottom: var(--spacer-4);
  }
}

ui-dialog.dialog__footer--justify-between {
  --dialog-footer-justify-content: space-between;
}

.table--collection {
  & ui-table-row:not([type="header"]) {
    height: var(--spacer-16);
  }

  @media (--screen-md-down) {
    --table-row-grid-template-columns: auto 1fr auto;
  }
}

.data-grid {
  --ui-table-row-border-bottom: 0;
  --table-cell-background: var(--color-surface);

  @media (--screen-md-down) {
    --table-row-grid-template-columns: 1fr auto;
  }

  & :not(ui-table-row[type="header"]) ui-table-cell {
    height: var(--spacer-16);
  }

  & ui-table-row {
    column-gap: 1px;
    margin-bottom: -1px;
    border-bottom: 1px solid var(--color-base-200);
  }

  & ui-table-row:not([type="header"]):not([checked]):not(.table-row--skeleton) {
    &:hover {
      --table-cell-background: var(--color-base-100);
    }

    &:focus-within {
      --table-cell-background: var(--color-base-50);
    }
  }

  & ui-table-cell {
    box-shadow: 0 0 0 1px var(--color-base-200);
  }
}

.table__column-shadow {
  opacity: 0;
  position: absolute;
  right: calc(var(--spacer-4) * -1);
  top: 0;
  bottom: 0;
  width: var(--spacer-4);
  pointer-events: none;
  box-shadow: inset 5px 0 7px -7px rgb(31 28 25 / 40%);
  z-index: 20;
  transition: 200ms ease opacity;
}

.table--scrolled-left {
  --data-grid-cell-end-shadow-opacity: 1;

  & .table__column-shadow {
    opacity: 1;
  }
}

@media (--screen-lg-up) {
  .data-grid-row__controls {
    opacity: 0;
  }
}

ui-data-grid-row[checked] .data-grid-row__controls,
.modal--visible .data-grid-row__controls {
  display: none;
}

ui-data-grid-row {
  &:hover .data-grid-row__controls {
    opacity: 1;
    background: var(--table-cell-background);
  }

  &:focus-within .data-grid-row__controls {
    background: var(--color-base-50);
  }
}

/* Mobile select list items */
.mobile-select-list-item {
  pointer-events: none;
}

.mobile-select-list-item button {
  display: none;
}

.mobile-select-check {
  position: absolute;
  left: 0;
  opacity: 0;
  transform: scale(0.9);
  transition:
    opacity 100ms ease,
    transform 200ms ease;
}

.mobile-select--active {
  user-select: none;

  & rp-page-header ui-action-button-group {
    opacity: 0;
    pointer-events: none;
  }
  & .mobile-select-element--hidden {
    display: none;
  }

  & .mobile-select-list-item {
    pointer-events: none;
  }

  & .mobile-select-list-item button {
    display: flex;
  }

  & ui-table-row[checked] .mobile-select-check,
  & ui-data-grid-row[checked] .mobile-select-check {
    transform: scale(1);
    opacity: 1;
  }
}

@media (--screen-md-down) {
  ui-table-row[checked] .data-row-check-replace,
  ui-data-grid-row[checked] .data-row-check-replace {
    opacity: 0;
  }
}

ui-table.has-selected-rows .with-checked--hidden {
  opacity: 0;
  pointer-events: none;
}

.table--submitted.table--invalid
  ui-data-grid-cell[invalid]:not([disabled], [readonly]) {
  background: var(--color-error-100);
}

.table--imported ui-data-grid-cell[invalid]:not([disabled], [readonly]) {
  background: var(--color-error-100);
}

/* Safari issue - overflow with z-index */
.safari {
  & ui-data-grid-cell {
    &:not([disabled], [readonly]):hover,
    &:not([disabled], [readonly]):focus,
    &:not([disabled], [readonly]):focus-within {
      z-index: auto;
    }
  }
}

/**
 * ==============================================
 * Dot flashing animation for autosave draft
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--color-base-400);
  color: var(--color-base-400);
  translate: 10px;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--color-base-400);
  color: var(--color-base-400);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 10px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background-color: var(--color-base-400);
  color: var(--color-base-400);
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: var(--color-base-400);
  }
  50%,
  100% {
    background-color: rgba(65, 65, 65, 0.2);
  }
}

/* List and table controls */
.collection-manage-control-bar {
  left: 0;
  right: 0;
  z-index: 40;
  background: var(--color-surface);
}

.collection-manage-controls-container--full-view {
  display: contents;
}

@media (--screen-sm-down) {
  .collection-manage-control-bar {
    padding-inline: var(--spacer-4);
  }
}

@media (--screen-md-down) {
  .collection-manage-control-bar {
    position: fixed;
    top: 0;
  }

  .collection-manage-control-bar:not(
      .collection-manage-control-bar--has-mobile-controls
    ),
  .collection-manage-control-bar:not(
      .collection-manage-control-bar--has-selected-items
    ) {
    display: none;
  }
}

@media (--screen-md) {
  .collection-manage-control-bar {
    padding-inline: var(--spacer-6);
  }
}

@media (--screen-lg-up) {
  .collection-manage-control-bar {
    display: block;
    position: sticky;
    top: calc(var(--spacer-2) * -1);
    z-index: 30;
    padding-top: var(--spacer-4);
    margin-top: calc(var(--spacer-4) * -1);
    border-bottom: 1px solid var(--color-border);
  }

  .collection-manage-controls-container--bottom .collection-manage-control-bar {
    border-top: 1px solid var(--color-border);
    border-bottom: none;
  }

  .collection-manage-controls-container--full-view
    .collection-manage-control-bar {
    top: 0;
    padding-top: 0;
    margin-top: 0;
  }
}

.collection-manage-table-header {
  position: sticky;
  z-index: 20;
  min-width: 100%;
  max-width: 0;
  overflow-x: auto;
  top: 65px;
  overscroll-behavior: none;
  background: var(--color-surface);
}

#multi-new-edit-table .collection-manage-table-header {
  top: 57px;
}

/**
 * ==============================================
 * Layout
 * ==============================================
 */
.main-wrapper-x {
  padding-left: var(--spacer-4);
  padding-right: var(--spacer-4);

  @media (--screen-lg-up) {
    padding-left: var(--spacer-6);
    padding-right: var(--spacer-6);
  }
}

.layout--print {
  --color-background: #fff;
  --color-base: #000;
}

.app-switcher-button::part(icon-end) {
  --icon-size: 0.6em;
}

ui-app-shell.content-part--free::part(content),
.route--messages ui-app-shell::part(content) {
  overflow: hidden;
}

ui-app-shell.content-part--free::part(content-inner),
.route--messages ui-app-shell::part(content-inner) {
  padding: 0;
}

ui-app-shell::part(content) {
  overscroll-behavior-x: auto;
}

/* Needed for Datadog session replay */
ui-app-shell[layout="contents"] {
  display: contents;
}
/* Needed for Datadog session replay */
ui-app-shell {
  display: grid;
}

/* Develop */
@media (--screen-md) {
  ui-app-shell::part(search) {
    min-width: 360px;
  }

  .is-using-inventory {
    ui-app-shell::part(search) {
      min-width: 160px;
    }
  }
}

@media (--screen-lg-up) {
  ui-app-shell::part(search) {
    width: 480px;
  }

  .is-using-inventory {
    ui-app-shell::part(search) {
      width: 280px;
    }
  }

  ui-app-shell::part(account-menu) {
    margin-left: var(--spacer-12);
  }
}

@media (--screen-xl-up) {
  .is-using-inventory {
    ui-app-shell::part(search) {
      width: 480px;
    }
  }
}

@media (--screen-sm-down) {
  rp-app-search > ui-app-search[open] {
    position: fixed;
    left: 0;
    right: 0;
    top: 10px;
    z-index: 1;
    padding-inline: var(--spacer-2);
  }

  rp-app-search > ui-app-search[open]::part(listbox) {
    max-width: none;
  }
}

@media (--screen-md) {
  rp-app-search > ui-app-search {
    min-width: 360px;
  }

  .is-using-inventory {
    rp-app-search > ui-app-search {
      min-width: 160px;
    }
  }
}

@media (--screen-lg-up) {
  rp-app-search > ui-app-search {
    width: 480px;
  }

  .is-using-inventory {
    rp-app-search > ui-app-search {
      width: 280px;
    }
  }
}

@media (--screen-xl-up) {
  .is-using-inventory {
    rp-app-search > ui-app-search {
      width: 480px;
    }
  }
}

rp-app-search ui-tab[aria-selected="true"] {
  background: var(--color-base-800);
  color: var(--color-surface);
  font-weight: 400;
}

rp-app-search ui-tab {
  background: var(--color-base-100);
  border-radius: var(--border-radius-xl);
}

/* TODO: Juice Box */
.combobox--blank-listbox-hidden.blank::part(listbox) {
  display: none;
}

ui-callout[heading=""]::part(header) {
  display: none;
}

ui-dialog.dialog--has-header-back::part(header) {
  flex-direction: row-reverse;
}

ui-dialog [slot="header-actions"].dialog__header-back-button {
  margin-inline: calc(var(--spacer-2) * -1) var(--spacer-4);
}

ui-chip-set.chips--wrap {
  & ui-chip {
    height: auto;
    min-height: var(--spacer-8);
  }

  & ui-chip::part(content) {
    white-space: normal;
    padding-block: var(--spacer-1);
  }
}

ui-chip[type="filter"].chip--active {
  --form-control-border-color: var(--form-control-border-color-focus);

  color: var(--color-base-500);
  font-weight: 500;
  background: var(--color-base-100);
}

.add-user-button {
  --icon-size: 1em;

  color: var(--color-surface);
  width: var(--spacer-8);
  height: var(--spacer-8);
  background: #438b7c;
  border-radius: 9999px;
  outline: 2px solid var(--color-background);
}

.share-item {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacer-2);
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--spacer-4);
  font-size: var(--font-size-sm);
  text-decoration: none;
  overflow-wrap: break-word;
  border: 1px solid var(--color-border);
  border-radius: var(--border-radius-lg);

  &:hover {
    background-color: var(--color-base-100);
  }
}

.listbox-add-button {
  width: 100%;
  justify-content: start;
  text-decoration: none;
  border-radius: 0;
}

.listbox-add-button::part(control) {
  padding: 0 var(--spacer-4);
}

.product-library-dialog::part(content) {
  padding: var(--spacer-6);
}

.product-library-dialog::part(control) {
  min-height: calc(100% - var(--spacer-8));
  width: 100%;
}

@media (--screen-md-up) {
  .product-library-dialog::part(control) {
    min-width: 736px;
  }

  ui-dialog.dialog__primary-button--disabled::part(primary-button) {
    pointer-events: none;
    opacity: 0.5;
  }
}

.number-field--item {
  display: flex;
  gap: var(--spacer-2);

  & ui-number-field:not(:focus-within) {
    --form-control-border: transparent;
  }

  & ui-number-field::part(control) {
    background: var(--color-base-200);
    border-radius: var(--rounded-full);
    text-align: center;
  }
}

ui-option[disabled] {
  opacity: 0.5;

  &::part(content) {
    background: var(--color-surface);
    margin-right: -32px;
    z-index: 1;
  }
}
