.unreset p {
  margin: 0 0 var(--spacer-8);
}

.unreset blockquote,
.unreset figure {
  margin: var(--spacer-4) var(--spacer-10);
}

.unreset hr {
  border: 1px solid var(--color-border);
}

.unreset h1,
.unreset h2,
.unreset h3,
.unreset h4,
.unreset h5,
.unreset h6 {
  font-weight: 500;
}

.unreset h1 {
  font-size: var(--font-size-4xl);
  margin: var(--spacer-2) 0;
}

.unreset h2 {
  font-size: var(--font-size-2xl);
  margin: var(--spacer-3) 0;
}

.unreset h3,
.unreset h4,
.unreset h5,
.unreset h6 {
  margin: var(--spacer-3) 0;
}

.unreset h3 {
  font-size: var(--font-size-lg);
}

.unreset h4 {
  font-size: var(--font-size-base);
}

.unreset h5 {
  font-size: var(--font-size-sm);
}

.unreset h6 {
  font-size: var(--font-size-xs);
}

.unreset ul,
.unreset ol {
  display: grid;
  row-gap: 0.25em;
  list-style-type: disc;
  margin: 1em 0;
  padding: 0 0 0 1em;
}

.unreset ul {
  list-style: disc;
}

.unreset ul ul {
  list-style-type: circle;
}

.unreset ol {
  list-style: decimal;
}
.unreset ol ol {
  list-style-type: lower-alpha;
}

.unreset :is(ul, ol) ul {
  list-style-type: circle;
}

.unreset :is(ul, ol) ul ul,
.unreset :is(ul, ol) ol ul {
  list-style-type: square;
}

.unreset dd {
  padding-left: 1em;
}

.unreset dl {
  margin: 1em 0;
}

.unreset :is(ul, ol, dl) ul,
.unreset :is(ul, ol, dl) ol,
.unreset :is(ul, ol, dl) dl {
  margin: 0;
}

.unreset li {
  margin: 0 0 0 1em;
}

.unreset legend {
  padding: 0 var(--spacer-1);
}

.unreset fieldset {
  margin: 0 var(--spacer-1);
  padding: 0 var(--spacer-1);
}

.unreset b,
.unreset strong {
  font-weight: bold;
}

.unreset pre {
  margin: 0 var(--spacer-4);
}

.unreset kbd {
  background: var(--kbd-background-color); /* #303742 */
  border-radius: 0.125rem;
  color: var(--kbd-color); /* #fff */
  font-size: var(--font-size-sm);
  line-height: 1.25;
  padding: 0.125rem 0.25rem;
}

.unreset mark {
  background: var(--mark-background-color); /* #ffe9b3 */
  border-bottom: 1px solid var(--mark-border-color); /* #ffd367 */
  border-radius: 0.125rem;
  color: var(--mark-color); /* #3b4351 */
  padding: 0.0625rem 0.125rem 0;
}

.unreset q::before,
.unreset q::after {
  content: "\0022";
}

.unreset blockquote {
  border-left: 2px solid var(--blockquote-border-color); /* #dadee4 */
  margin-left: 0;
  padding: var(--spacer-2) var(--spacer-4);
}

.unreset blockquote p:last-child {
  margin-bottom: 0;
}

.unreset dl dt {
  font-weight: bold;
}

.unreset dl dd {
  margin: var(--spacer-2) 0 var(--spacer-4) 0;
}

.unreset sub,
.unreset sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

.unreset sub {
  bottom: -0.25em;
}

.unreset sup {
  top: -0.5em;
}

.unreset [role="link"] {
  cursor: pointer;
}

.unreset pre,
.unreset code,
.unreset kbd,
.unreset samp {
  font-family: var(--font-family-mono), SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

.unreset pre {
  margin-top: 0;
  margin-bottom: var(--spacer-4);
  overflow: auto;
}
